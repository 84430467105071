<template>
  <div id="littleredfashion">

    <div id="preloader-active" v-if="preloader">
      <div class="preloader d-flex align-items-center justify-content-center">
        <div class="preloader-inner position-relative">
          <div class="preloader-circle"></div>
          <div class="preloader-img pere-text">
            <img src="../../assets/littleredfashion/Images/logo.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <header>

      <div class="header-area">
        <div class="main-header header-sticky">
          <div class="container-fluid">
            <div class="row menu-wrapper align-items-center justify-content-between">
              <div class="header-left d-flex align-items-center">

                <div class="logo">
                  <a href="https://littleredfashion.com/"><img src="../../assets/littleredfashion/Images/Irf-logo-icon.png" alt=""></a>
                </div>

                <div class="logo2">
                  <a href="https://littleredfashion.com/"><img src="../../assets/littleredfashion/Images/logo2.png" alt=""></a>
                </div>

                <div class="main-menu" :class="{'active-menu': activeMenu}">
                  <nav>
                    <ul id="navigation">
                      <li><a href="/">Home</a></li>
                      <li><a href="" @click.prevent="scrollToSection('about')">About</a></li>
                      <li><a href="" @click.prevent="scrollToSection('features')">Features</a></li>
                      <li><a href="" @click.prevent="scrollToSection('footer')">Contact</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="header-right1 d-flex align-items-center">
                <div class="search">
                  <ul class="d-flex align-items-center social-icons-wrapper footer-social">
                    <li>
                      <a href="https://www.facebook.com/LittleRedFashion" class="" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/LittleRedFashionCo/" class="" target="_blank">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/little-red-fashion/" class="" target="_blank">
                        <i class="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCaWA6iZyIj78ff6BgqxaI7w" class="" target="_blank">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-12">
                <div class="mobile_menu d-block d-lg-none">
                  <div class="slicknav_menu" @click="activeMenu = !activeMenu">
                    <div id="nav-icon1" class="slicknav_btn slicknav_collapsed" :class="{'open': activeMenu}">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </header>

    <main>

      <div class="slider-area ">
        <div class="slider-active">
          <div class="single-slider hero-overly1 slider-height d-flex align-items-center slider-bg1">
            <div class="container">
              <div class="row">
                <div class="col-xl-6 col-lg-4 col-md-2"></div>
                <div class="col-xl-6 col-lg-8 col-md-10">
                  <div class="hero__caption"
                     data-aos="fade-up"
                     data-aos-duration="1000"
                     data-aos-offset="300"
                     data-aos-easing="ease-in-sine"
                  >
                    <span>Fashion is for Everyone</span>
                    <h1 data-animation="fadeInUp" data-delay=".4s" class="" style="animation-delay: 0.4s;">
                      Little Red Fashion
                    </h1>
                    <div class="hero-slider-text" data-animation="fadeInUp" data-delay=".6s" style="animation-delay: 0.6s;">
                      Little Red Fashion is the first kids fashion education company! They are an ed-tech and publishing start up obsessed with two things:
                      <ul>
                        <li>Giving kids who love fashion new ways to learn about it through books, apps, and physical solutions at the cutting edge of developmental research in ways that make fashion history accessible and fun.</li>
                        <li>Giving all kids digitally delivered educational materials in arts, literacy, and STEM fields based on fashion to teach in new ways! We firmly believe fashion can help make these subjects more relatable and interesting.</li>
                      </ul>
                    </div>

                    <div class="hero__btn" data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">
                      <a href="https://littleredfashion.com/about" target="_blank" class="btn hero-btn" tabindex="0">Discover More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <section class="properties new-arrival fix" id="about">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-12">
              <div class="section-tittle mb-60 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                <h2>About</h2>
                <p class="pera">
                  By utilizing the <a href="https://www.flexiblesites.com/" class="about-section-link" target="_blank">Flexible Sites</a> platform our team was able to build a custom site quickly that included custom developed features. The first phase was to launch the public facing portion of the site in one month and then continue development for registered youth to provide them access to the tools and features of the platform.
                </p>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-md-6 d-flex justify-content-center">
              <img src="../../assets/littleredfashion/Images/profile_page.jpg" class="round-img" alt="">
            </div>
            <div class="col-md-6 section-tittle d-flex align-items-center">
              <p class="pera">
                The platform allows for registered users to create a profile in which they can share their drawings made on the platform or outside of it by uploading to their profile and allowing others to see. The platform allows for following and featuring other people's work in the community.
              </p>
            </div>

          </div>

          <div class="row">
            <div class="col-md-6 section-tittle d-flex align-items-center">
              <p class="pera">
                The sketchpad provides a way for youth to draw their own sketches using templates while also learning about the various articles of clothing. The content is stored in a CMS database and can be edited any time by the LRF team without any coding or help from the development team.
              </p>
            </div>
            <div class="col-md-6 d-flex justify-content-center">
              <img src="../../assets/littleredfashion/Images/sketchpad.jpg" class="round-img" alt="">
            </div>

          </div>
        </div>
      </section>


      <div class="visit-tailor-area fix">

        <div class="tailor-offers"></div>

        <div class="tailor-details">
          <h2>ETHICAL PUBLISHING</h2>
          <p>The Little Red Dress children's book is written by the founders of LRF and in the future there will be a series of books detailing the cycle of creation to sales for an article of clothing in different cultures. The platform has an ecommerce section which allows the founders to easily create referral codes and track all orders while also providing a digital download process after a visitor purchases their book.</p>
          <a href="https://littleredfashion.com/book" target="_blank" class="btn">Get Your Copy</a>
        </div>
      </div>


      <div class="new-arrival new-arrival2" id="features">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8 col-md-10">
              <div class="section-tittle mb-60 text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                <h2>Features</h2>
                <P>Security and speed are paramount to being compliant for the safety of all users</P>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6"
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-offset="300"
               data-aos-easing="ease-in-sine"
            >
              <div class="single-new-arrival mb-50 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                <div class="popular-img">
                  <img src="../../assets/littleredfashion/Images/net.png" width="100" alt="">
                </div>
                <div class="popular-caption">
                  <h3>C# .Net Core</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6"
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-offset="300"
               data-aos-easing="ease-in-sine"
            >
              <div class="single-new-arrival mb-50 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                <div class="popular-img">
                  <img src="../../assets/littleredfashion/Images/azure.png" alt="">
                </div>
                <div class="popular-caption">
                  <h3>Microsoft Azure</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6"
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-offset="300"
               data-aos-easing="ease-in-sine"
            >
              <div class="single-new-arrival mb-50 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                <div class="popular-img">
                  <img src="../../assets/littleredfashion/Images/database.png" alt="">
                </div>
                <div class="popular-caption">
                  <h3>SQL Server Database</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="instagram-area" id="instagram-area">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-xl-3 col-lg-4 col-md-12">
              <div class="instra-tittle mb-40">
                <div class="section-tittle">
                  <img src="../../assets/littleredfashion/Images/fs-logo.png" width="50" alt="">
                  <h2>Flexible Sites</h2>
                  <P class="mb-35">The fastest and easiest way to get a professional site up and running while also having a team of developers at your disposal.</P>
                  <a href="https://www.flexiblesites.com" class="border-btn">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-lg-8">
              <div class="row no-gutters">
                <div class="col-xl-6 col-lg-6 col-md-12">
                  <a class="single-instagram d-block" target="_blank" href="https://littleredfashion.com/main">
                    <img src="../../assets/littleredfashion/Images/parents-bg.jpg" alt="" class="w-100">
                    <a href="#"><i class="ti-instagram"></i></a>
                  </a>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12">
                  <a class="single-instagram d-block" target="_blank" href="https://littleredfashion.com/kids">
                    <img src="../../assets/littleredfashion/Images/kids-bg.jpg" alt="" class="w-100">
                    <a href="#"><i class="ti-instagram"></i></a>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="categories-area section-padding40 gray-bg"></div>

    </main>
    <footer>
      <div class="footer-wrapper" id="footer">

        <div class="footer-area footer-padding">
          <div class="container ">
            <div class="row justify-content-between">
              <div class="col-xl-4 col-lg-6 col-sm-12">
                <div class="single-footer-caption mb-50">
                  <div class="single-footer-caption mb-30">

                    <div class="footer-logo mb-35">
                      <a href="/"><img src="../../assets/img/logo.png" width="200" alt=""></a>
                    </div>
                    <div class="footer-tittle">
                      <div class="footer-pera">
                        <ul>
                          <li>
                            <i class="fa fa-map-marker-alt"></i>
                            <span>P.O. Box 1100 Bala Cynwyd, PA</span>
                          </li>
                          <li>
                            <i class="fa fa-envelope"></i>
                            <span>info@oceanringtech.com</span>
                          </li>
                          <li>
                            <i class="fa fa-phone"></i>
                            <span>(856) 448-4368</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="footer-social">
                      <a href="https://twitter.com/oceanringtech"><i class="fab fa-twitter"></i></a>
                      <a href="https://www.facebook.com/oceanringtech/"><i class="fab fa-facebook-f"></i></a>
                      <a href="https://www.linkedin.com/company/14395475"><i class="fab fa-linkedin"></i></a>
                      <a href="https://www.instagram.com/oceanringtech/"><i class="fab fa-instagram"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-sm-12">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Company</h4>
                    <p>
                      Flexible Sites allows customers to create and publish professional-quality,
                      mobile-responsive content in a matter of minutes so they can invest their time on what is important for their organization.
                      We are a software solutions company helping clients develop,
                      manage and modernize applications to accelerate their digital transformation and business results.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-sm-12">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Newsletter</h4>
                    <p>Subscribe to our newsletter to keep up to date. We will not send you spam email.</p>

                    <div id="mc_embed_signup">
                      <form
                        action="https://oceanringtech.us4.list-manage.com/subscribe/post?u=e3e1dfb0375e0ab1d648fc68b&amp;id=a738f8deb7"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate"
                        target="_blank"
                        novalidate
                      >
                        <div id="mc_embed_signup_scroll" class="footer__form newsletter-form">

                          <div class="contact-form">
                            <div class="mc-field-group input-email">
                              <input type="email" value="" name="EMAIL" class="required footer__form-input no-outline" id="mce-EMAIL" placeholder="Enter your E-mail" required>
                            </div>
                            <div class="clear">
                              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="footer__form-submit button--primary">
                            </div>
                          </div>

                          <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display:none"></div>
                            <div class="response" id="mce-success-response" style="display:none"></div>
                          </div>

                          <div style="position: absolute; left: -5000px;" aria-hidden="true">
                            <input type="text" name="b_39fac2c9b8e4ec17550bf57cf_7388c0b381" tabindex="-1" value="">
                          </div>

                        </div>
                      </form>
                    </div>

                    <h2>Little Red Fashion</h2>
                    <small>Copyright © {{new Date().getFullYear()}} Little Red Fashion Inc.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </footer>

    <div id="back-top">
      <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import loadScript from "../../services/loadScript";
  import mailchimpScript from '../../services/mailchimp';

  export default {
    name: "littleredfashion",
    data() {
      return {
        preloader: true,
        activeMenu: false
      }
    },
    methods: {
      scrollToSection(id) {
        if (!id) {
          return
        }
        this.activeMenu = false;
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    mounted() {
      loadScript(() => {
      }, mailchimpScript);
      setTimeout(() => {
        this.preloader = false;
      }, 1000);

      $('#back-top a').on("click", function () {
        $('body,html').animate({
          scrollTop: 0
        }, 800);
        return false;
      });
    }
  }
</script>

<style scoped>
  @import url('../../assets/littleredfashion/Stylesheet/bootstrap.min.css');
  @import url('../../assets/littleredfashion/Stylesheet/slicknav.css');
  @import url('../../assets/littleredfashion/Stylesheet/flaticon.css');
  @import url('../../assets/littleredfashion/Stylesheet/progressbar_barfiller.css');
  @import url('../../assets/littleredfashion/Stylesheet/price_rangs.css');
  @import url('../../assets/littleredfashion/Stylesheet/animate.min.css');
  @import url('../../assets/littleredfashion/Stylesheet/animated-headline.css');
  @import url('../../assets/littleredfashion/Stylesheet/magnific-popup.css');
  @import url('../../assets/littleredfashion/Stylesheet/fontawesome-all.css');
  @import url('../../assets/littleredfashion/Stylesheet/slick.css');
  @import url('../../assets/littleredfashion/Stylesheet/nice-select.css');
  @import url('../../assets/littleredfashion/Stylesheet/style.css');

  .about-section-link {
    color: #9f2c2a;
  }
  .contact-form {
    display: flex;
  }
  #mce-responses {
    color: #fff;
    padding: 15px 0;
  }
  #mce-success-response {
    color: #5de146;
  }
</style>
